import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Table, TableRow, TableCell } from '@mui/material';

export interface SimpleDialogProps {
  open: boolean;
  filename: string | undefined;
  data: any;
  onClose: () => void;
}

export function DataDialog(props: SimpleDialogProps) {
  const { onClose, open, filename, data } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{filename}</DialogTitle>
      <Table>
        {Object.keys(data).map((key: string) => (
        <TableRow>
            <TableCell>{key}</TableCell>
            <TableCell>
                <List sx={{ pt: 0 }}>
                    {data[key].map((el: any, i) => (
                    <ListItem key={i}>
                        <ListItemText primary={el} />
                    </ListItem>
                    ))}
                </List>
            </TableCell>
        </TableRow>
        ))}
      </Table>
    </Dialog>
  );
}
