import {createContext, useState} from "react";

const DataContext = createContext<any>({});

const DataContextProvider = ({children}: any) => {
    const [data, setData] = useState<any>({});

    return (
        <DataContext.Provider value={{ data, setData }}>
            {children}
        </DataContext.Provider>
    )
}

export { DataContext, DataContextProvider }