import { createContext, FC, useState } from "react";
import { createTheme } from "@mui/material";

interface IThemeProps {
    theme: any
    toggleTheme: () => any;
}

const darkTheme = createTheme({
    palette: {
        mode: 'dark'
    }
})

const lightTheme = createTheme({
    palette: {
        mode: 'light'
    }
})

const ThemeContext = createContext<IThemeProps>({ theme: darkTheme, toggleTheme: () => null});

const ThemeProvider: FC<{children: any}> = (props) => {
    const [theme, setTheme] = useState(darkTheme);

    const toggleTheme = () => setTheme(theme.palette.mode === 'dark'?lightTheme:darkTheme);

    return (
        <ThemeContext.Provider value={{ theme: theme, toggleTheme: toggleTheme }}>
            {props.children}
        </ThemeContext.Provider>
    )
}

export {ThemeContext, ThemeProvider}