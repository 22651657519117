import { useContext, useState } from "react";
import { DataContext } from "../context/DataContext";
import Paper from '@mui/material/Paper';
import { ListItem, ListItemText, List } from "@mui/material";
import { DataDialog } from "./DataModal";

const DataDisplay = () => {
    const { data } = useContext(DataContext);
    const [open, setOpen] = useState<boolean>(false);
    const [filename, setFilename] = useState<string>('abc');

    const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, filename: string) => {
        setOpen(true);
        console.log(data[filename])
        setFilename(filename)
    }

    return (
        <Paper elevation={12}>
            <List>
                {Object.keys(data!== null || data !== undefined? data:{}).map((key: string) => (
                    <ListItem button onClick={(event) => handleOpen(event, key)} key={key}>
                        <ListItemText primary={key}/>
                    </ListItem>
                ))}
            </List>
            {(data[filename]!==null && data[filename]!==undefined) && <DataDialog open={open} onClose={() => setOpen(false)} filename={filename} data={data[filename]}/>}
        </Paper>
    )
}

export default DataDisplay;