import { 
	Paper, 
	IconButton, 
	InputBase, 
	Divider 
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useContext, useState } from "react";
import ThemeButton from "./ThemeButton";
import { DataContext } from "../context/DataContext";
import { ProgressContext } from "../context/ProgressContext";

const Search = () => {
  const [search, setSearch] = useState<string>('');
  const { setData } = useContext(DataContext);
  const { setProgress } = useContext(ProgressContext)

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setProgress(true);
      const { data } = await axios.get(`https://salesleads.fairshare.tech/server/test?param=${search}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => res);
      setData(data);
      setProgress(false);
    } catch (err) {
      console.log(err)
    }
  }

    return (
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', maxWidth: 600, justifySelf: 'center' }}
			elevation={10}
      onSubmit={handleSubmit}
    >
      <IconButton sx={{ p: '10px' }} aria-label="menu">
        <MenuIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Anything"
        id="search"
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e: any) => setSearch(e.target.value)}
      />
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <ThemeButton/>
    </Paper>
    )
}

export default Search;