import {
    AppBar,
    IconButton, 
    Toolbar, 
    Typography
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ThemeButton from "./ThemeButton";

const Navbar = () => {
    return (
    <AppBar position="static">
        <Toolbar>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
            >
                <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                FairShare
            </Typography>
            <ThemeButton/>
        </Toolbar>
    </AppBar>
    )
}

export default Navbar;