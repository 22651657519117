import React, { useContext } from "react";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import {
    IconButton
} from '@mui/material';
import {
    ThemeContext
} from '../context/ThemeContext';

const ThemeButton: React.FC = () => {
    const {theme, toggleTheme} = useContext(ThemeContext);
    
    return (
        <IconButton onClick={toggleTheme}>
            {theme.palette.mode === 'dark'?<LightModeIcon/>:<DarkModeIcon/>}
        </IconButton>
    )
}

export default ThemeButton;