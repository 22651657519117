import { createContext, useState } from "react";

const ProgressContext = createContext<any>({});

const ProgressContextProvider = ({children}: any) => {
    const [progress, setProgress] = useState<boolean>(false);

    return (
        <ProgressContext.Provider value={{ progress, setProgress }}>
            {children}
        </ProgressContext.Provider>
    )
}

export { ProgressContext, ProgressContextProvider }