import React, { useContext } from 'react';
import {
  Grid,
  CssBaseline,
  ThemeProvider,
  CircularProgress
} from '@mui/material';
import { ThemeContext } from './context/ThemeContext';
import Navbar from './components/Navbar';
import Search from './components/Search';
import { ProgressContext } from './context/ProgressContext';
import {Route, Routes} from "react-router-dom";
import Login from './pages/Login';
import DataDisplay from './components/DataDisplay';
import { DataContext } from './context/DataContext';

function App() {
  const {theme} = useContext(ThemeContext);
  const {data} = useContext(DataContext)
  const {progress} = useContext(ProgressContext)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="/main" element={(
          <>
            <Navbar/>
            <Grid container alignItems="center" justifyContent="center" padding={5} spacing={5}>
              <Grid item xs={12} md={4}>
                <Search/>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: "center"}}>
                {progress && <CircularProgress thickness={7.5}/>}
                {(data!==null && data!==undefined) && <DataDisplay/>}
              </Grid>
            </Grid>
          </>
        )}/>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
